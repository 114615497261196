
.gland {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
:deep(.ant-image-mask) {
  height: 20px;
  width: 20px;
  opacity: 1;
  left: unset;
  top: unset;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url("../../assets/images/magnifier1.png");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  z-index: 5;
  .ant-image-mask-info {
    display: none;
  }
}
