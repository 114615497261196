
.page-header {
  display: flex;
  background: #192a87;
  height: 60px;
  align-items: center;
  padding: 0 25px;
  justify-content: space-between;
  display: flex;
  .logo {
    display: flex;
    align-items: center;
    .title {
      font-size: 30px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 60px;
      margin-left: 15px;
    }
  }
  .header-right {
    color: #ffffff;
    font-size: 12px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    .nav {
      margin-left: 12px;
      display: flex;
    }
    .date {
      padding-right: 13px;
      border-right: 1px solid #fff;
    }

    .text {
      line-height: 24px;
    }
    .icon {
      margin-right: 4px;
    }
  }
}

.important-tooltip {
  animation: imp 3s;
  animation-iteration-count: infinite;
}
