
.pagination-box {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
}
// ::v-deep {
//   .ant-table-cell {
//     display: flex;
//   }
// }

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  .status-drop {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 4px;
  }
}
