
.ant-btn-delete {
  color: var(--yy-error-color) !important;
  &:hover {
    color: var(--yy-error-color) !important;
  }
}
.ant-btn-delete[disabled] {
  color: var(--yy-error-color) !important;
  opacity: 0.35;
  &:hover {
    opacity: 0.35;
    color: var(--yy-error-color) !important;
  }
}
.confirm-primary {
  color: var(--yy-primary-color) !important;
  &:hover {
    color: var(--yy-primary-color) !important;
  }
}
