
.page-view {
  .search-wrapper {
    display: flex;
    align-items: flex-start;
    .search-form {
      flex: 1;
    }
  }
}
