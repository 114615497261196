
.page-title {
  height: 48px;
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  border-bottom: 1px solid var(--yy-gray-color);
  position: relative;
  padding: 0 12px;
  // margin-bottom: 16px;
  &::before {
    position: absolute;
    display: block;
    content: "";
    width: 4px;
    height: 24px;
    background: #158fff;
    top: 12px;
    left: 0;
  }
  .title {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #1d2129;
    line-height: 48px;
    text-align: left;
  }
}
