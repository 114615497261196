.side-menu {
  div,
  ul,
  li,.ant-menu-sub.ant-menu-inline {
    background-color: rgba(255, 255, 255, 0);
    color: #fff;
  }
  .ant-menu-light .ant-menu-submenu-title:hover {
    color: #fff;
  }
  .ant-menu-submenu
    > .ant-menu-submenu-title
    > .ant-menu-submenu-arrow {
    color: #fff;
  }
  .ant-menu-submenu> .ant-menu-submenu-title:hover{
        background-color: rgba(0, 0, 0, 0.1);
      }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu-item:active{
    background-color: #157efb;
  }
  .ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active{
    color: #fff;
  }
  //取消边距
  .ant-menu-inline .ant-menu-submenu-title ,.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,.ant-menu-inline .ant-menu-item {
    margin: 0;
    height: 50px;
  }
  .ant-menu-inline .ant-menu-item {
    margin-bottom: 0;
    height: 50px;
  }
}
